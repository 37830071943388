// src/generateContract.ts
import SwaggerParser from "@apidevtools/swagger-parser";

// src/context/createContext.ts
import "openapi3-ts";

// src/context/makeRefObjectResolvers.ts
import { isReferenceObject } from "openapi3-ts";

// src/domain/errors.ts
var RiskAnalysisTemplateIssue = class extends Error {
  code;
  detail;
  constructor({ code, detail }) {
    super(detail);
    this.code = code;
    this.detail = detail;
  }
};
function invalidStatusCodeError({
  method,
  path,
  statusCode
}) {
  return new RiskAnalysisTemplateIssue({
    code: "InvalidStatusCodeError",
    detail: `Invalid status code at path ${method} ${path}: ${statusCode}`
  });
}
function invalidHttpMethodError({
  method,
  path
}) {
  return new RiskAnalysisTemplateIssue({
    code: "InvalidHttpMethodError",
    detail: `Invalid HTTP method at path ${path}: ${method}`
  });
}
function invalidRefError({ ref }) {
  return new RiskAnalysisTemplateIssue({
    code: "InvalidRefError",
    detail: `Invalid reference found: ${ref}`
  });
}
function resolveRefError({ ref }) {
  return new RiskAnalysisTemplateIssue({
    code: "ResolveRefError",
    detail: `Could not resolve component reference: ${ref}`
  });
}
function circularRefDependencyError({
  depsPath
}) {
  return new RiskAnalysisTemplateIssue({
    code: "CircularRefDependencyError",
    detail: `Circular reference detected: ${depsPath.join(" -> ")}`
  });
}
function unexpectedError({ detail }) {
  return new RiskAnalysisTemplateIssue({
    code: "UnexpectedError",
    detail
  });
}
function missingSchemaInParameterObjectError({
  method,
  paramType,
  path
}) {
  return new RiskAnalysisTemplateIssue({
    code: "MissingSchemaInParameterObjectError",
    detail: `Missing schema in parameter ${paramType} at path ${method} ${path}`
  });
}
function unsupportedRequestBodyContentTypeError({
  contentType,
  method,
  path
}) {
  return new RiskAnalysisTemplateIssue({
    code: "UnsupportedRequestBodyContentTypeError",
    detail: `Unsupported content type at path ${method} ${path}: ${contentType}`
  });
}
function refResolutionDepthExceededError({
  ref
}) {
  return new RiskAnalysisTemplateIssue({
    code: "RefResolutionDepthExceededError",
    detail: `Ref resolution depth exceeded at ref: ${ref}
There might be a circular reference.`
  });
}

// src/lib/utils.ts
import camelcase from "camelcase";
import ts from "typescript";

// src/domain/validators.ts
import { P, match } from "ts-pattern";
function validateOpenAPIStatusCode({
  method,
  path,
  statusCode
}) {
  if (statusCode === "default") return;
  if (/^[1-5]XX$/.test(statusCode)) return;
  const statusCodeNumber = Number(statusCode);
  if (!Number.isInteger(statusCodeNumber) || statusCodeNumber < 100 || statusCodeNumber >= 600) {
    throw invalidStatusCodeError({ method, path, statusCode });
  }
}
function validateOpenAPIHttpMethod({
  method,
  path
}) {
  if (!["delete", "get", "head", "options", "patch", "post", "put", "trace"].includes(
    method.toLowerCase()
  )) {
    throw invalidHttpMethodError({ method, path });
  }
}
function validateRef(ref) {
  const componentPaths = [
    "schemas",
    "parameters",
    "requestBodies",
    "responses",
    "headers",
    "pathItems"
  ];
  const isValid = match(ref.split("/")).with(["#", "components", P.union(...componentPaths), P.string.minLength(1)], () => true).otherwise(() => false);
  if (!isValid) {
    throw invalidRefError({ ref });
  }
}

// src/lib/utils.ts
function astToString(ast, options = {}) {
  const { fileName = "contract.ts", formatOptions, sourceText = "" } = options;
  const sourceFile = ts.createSourceFile(
    fileName,
    sourceText,
    ts.ScriptTarget.ESNext,
    false,
    ts.ScriptKind.TS
  );
  sourceFile.statements = ts.factory.createNodeArray(Array.isArray(ast) ? ast : [ast]);
  const printer = ts.createPrinter({
    newLine: ts.NewLineKind.LineFeed,
    removeComments: false,
    ...formatOptions
  });
  return printer.printFile(sourceFile);
}
function noop() {
  return void 0;
}
var AstTsWriter = class {
  nodes = [];
  /**
   * Adds one or more nodes to the writer.
   * @param nodes The nodes to add.
   * @returns The updated `AstTsWriter` instance.
   */
  add(...nodes) {
    this.nodes.push(...nodes);
    return this;
  }
  /**
   * Converts the added nodes to a string representation.
   * @returns The string representation of the added nodes.
   */
  toString() {
    return astToString(this.nodes);
  }
};
var formatToIdentifierString = (str) => {
  const replacedStr = str.replace(/[^a-zA-Z0-9_$]/g, "_");
  return /^[a-zA-Z_$]/.test(replacedStr) ? replacedStr : "_" + replacedStr;
};
var convertPathToVariableName = (path) => camelcase(path.replaceAll(/(\/|\.|{)/g, "-").replaceAll("}", ""));
var parseRefComponents = (ref) => {
  validateRef(ref);
  const [
    _,
    // #/
    __,
    // components/
    type,
    // "(schemas|parameters|requestBodies|responses|headers)/"
    identifier
  ] = ref.split("/");
  return {
    identifier,
    type
  };
};
function generatePowerset(array) {
  const result = [];
  result.push([]);
  for (let i = 1; i < 1 << array.length; i++) {
    const subset = [];
    for (let j = 0; j < array.length; j++) if (i & 1 << j) subset.push(array[j]);
    result.push(subset);
  }
  return result;
}

// src/context/makeRefObjectResolvers.ts
function makeRefObjectResolvers(openAPIDoc) {
  function resolveRef(ref) {
    const { type } = parseRefComponents(ref);
    function recursevelyResolveRef(internalRef, depth = 0) {
      const { identifier, type: internalType } = parseRefComponents(internalRef);
      const objectOrRef = openAPIDoc.components?.[type]?.[identifier];
      if (depth > 100) {
        throw refResolutionDepthExceededError({ ref });
      }
      if (!objectOrRef || internalType !== type) {
        throw resolveRefError({ ref });
      }
      if (isReferenceObject(objectOrRef)) {
        return recursevelyResolveRef(objectOrRef.$ref, depth + 1);
      }
      return objectOrRef;
    }
    return recursevelyResolveRef(ref);
  }
  function resolveObject(refOrObject) {
    if (!isReferenceObject(refOrObject)) return refOrObject;
    const component = resolveRef(refOrObject.$ref);
    return resolveObject(component);
  }
  return { resolveObject, resolveRef };
}

// src/context/processApiOperationObjects.ts
import isEqual from "lodash/isEqual.js";
function processApiOperationObjects(openAPIDoc, resolveObject) {
  const pathsObject = openAPIDoc.paths;
  const operationObjects = [];
  if (!pathsObject) return [];
  for (const [path, pathItemOrRef] of Object.entries(pathsObject)) {
    if (!pathItemOrRef) continue;
    const pathItem = resolveObject(pathItemOrRef);
    const pathOperations = Object.entries(pathItem).filter(
      ([property]) => !["description", "parameters", "servers", "summary"].includes(property)
    );
    for (const [method, pathOperation] of pathOperations) {
      validateOpenAPIHttpMethod({ method, path });
      if (!pathOperation?.responses) continue;
      const parameters = (pathItem.parameters ?? []).concat(pathOperation.parameters ?? []).reduce((acc, param) => {
        if (acc.some((p) => isEqual(p, param))) return acc;
        return [...acc, param];
      }, []).map(resolveObject);
      const requestBody = pathOperation.requestBody ? resolveObject(pathOperation.requestBody) : void 0;
      const responsesEntries = Object.entries(pathOperation.responses);
      const responses = responsesEntries.reduce(
        (acc, [statusCode, response]) => {
          const resolvedResponse = resolveObject(response);
          validateOpenAPIStatusCode({ method, path, statusCode });
          return { ...acc, [statusCode]: resolvedResponse };
        },
        {}
      );
      const operationObject = {
        description: pathOperation.description,
        method,
        operationId: pathOperation.operationId,
        parameters,
        path,
        requestBody,
        responses,
        summary: pathOperation.summary
      };
      operationObjects.push(operationObject);
    }
  }
  return operationObjects;
}

// src/context/processComponentObjectSchemas.ts
import {
  isReferenceObject as isReferenceObject2
} from "openapi3-ts";
function processComponentObjectSchemas(openAPIDoc, getSchemaByRef) {
  const graph = createSchemaComponentsDependencyGraph(openAPIDoc, getSchemaByRef);
  const topologicallySortedRefs = topologicalSort(graph);
  const topologicallySortedSchemas = topologicallySortedRefs.map((ref) => {
    const schema = getSchemaByRef(ref);
    const { identifier } = parseRefComponents(ref);
    const normalizedIdentifier = formatToIdentifierString(identifier);
    return {
      identifier,
      normalizedIdentifier,
      ref,
      schema
    };
  });
  const componentSchemasMap = new Map(
    topologicallySortedSchemas.map((schema) => [schema.ref, schema])
  );
  return {
    componentSchemasMap,
    topologicallySortedSchemas
  };
}
function createSchemaComponentsDependencyGraph(openAPIDoc, getSchemaByRef) {
  const graph = {};
  const visitedRefs = {};
  const schemaComponents = openAPIDoc.components?.schemas;
  function visit(component, fromRef) {
    if (!(fromRef in graph)) {
      graph[fromRef] = /* @__PURE__ */ new Set();
    }
    if (isReferenceObject2(component)) {
      graph[fromRef].add(component.$ref);
      if (visitedRefs[component.$ref]) return;
      visitedRefs[fromRef] = true;
      visit(getSchemaByRef(component.$ref), component.$ref);
      return;
    }
    ["allOf", "oneOf", "anyOf"].forEach((key) => {
      component[key]?.forEach((subComponent) => {
        visit(subComponent, fromRef);
      });
    });
    if (component.type === "array" && component.items) {
      visit(component.items, fromRef);
      return;
    }
    if (component.type === "object" || component.properties || component.additionalProperties) {
      if (component.properties) {
        Object.values(component.properties).forEach((component2) => {
          visit(component2, fromRef);
        });
      }
      if (component.additionalProperties && typeof component.additionalProperties === "object") {
        visit(component.additionalProperties, fromRef);
      }
    }
  }
  if (schemaComponents) {
    Object.entries(schemaComponents).forEach(([name, schema]) => {
      visit(schema, `#/components/schemas/${name}`);
    });
  }
  return graph;
}
function topologicalSort(graph) {
  const sorted = [];
  const visited = {};
  function visit(name, ancestors = []) {
    ancestors.push(name);
    visited[name] = true;
    graph[name]?.forEach((dep) => {
      if (ancestors.includes(dep)) {
        const depsPath = [...ancestors, dep];
        throw circularRefDependencyError({ depsPath });
      }
      if (visited[dep]) return;
      visit(dep, ancestors.slice(0));
    });
    if (!sorted.includes(name)) sorted.push(name);
  }
  Object.keys(graph).forEach((name) => visit(name));
  return sorted;
}

// src/context/createContext.ts
function createContext(openAPIDoc) {
  const { resolveObject, resolveRef } = makeRefObjectResolvers(openAPIDoc);
  const { componentSchemasMap, topologicallySortedSchemas } = processComponentObjectSchemas(
    openAPIDoc,
    resolveRef
  );
  const apiOperationObjects = processApiOperationObjects(openAPIDoc, resolveObject);
  return {
    apiOperationObjects,
    componentSchemasMap,
    openAPIDoc,
    resolveObject,
    resolveRef,
    topologicallySortedSchemas
  };
}

// src/converters/apiOperationToAstTsRestContract.ts
import camelcase2 from "camelcase";
import "openapi3-ts";
import { match as match5 } from "ts-pattern";

// src/domain/constants.ts
var POSSIBLE_STATUS_CODES_TS_REST_OUTPUT = [
  "200",
  "201",
  "204",
  "400",
  "401",
  "403",
  "404",
  "405",
  "409",
  "415",
  "500"
];
var APPLICATION_JSON = "application/json";
var TS_REST_RESPONSE_BODY_SUPPORTED_CONTENT_TYPES = [
  APPLICATION_JSON,
  "multipart/form-data",
  "application/x-www-form-urlencoded"
];

// src/lib/ts.ts
import { P as P2, match as match2 } from "ts-pattern";
import ts2 from "typescript";
function tsNamedImport({
  from,
  import_
}) {
  return ts2.factory.createImportDeclaration(
    void 0,
    ts2.factory.createImportClause(
      false,
      void 0,
      ts2.factory.createNamedImports(
        import_.map(
          (name) => ts2.factory.createImportSpecifier(false, void 0, ts2.factory.createIdentifier(name))
        )
      )
    ),
    ts2.factory.createStringLiteral(from),
    void 0
  );
}
function tsLiteralOrExpression(value) {
  return match2(value).with(P2.string, (value2) => ts2.factory.createStringLiteral(value2)).with(
    P2.number,
    (v) => v < 0,
    (v) => ts2.factory.createPrefixUnaryExpression(
      ts2.SyntaxKind.MinusToken,
      ts2.factory.createNumericLiteral(Math.abs(v))
    )
  ).with(P2.number, (value2) => ts2.factory.createNumericLiteral(value2)).with(true, () => ts2.factory.createTrue()).with(false, () => ts2.factory.createFalse()).with(null, () => ts2.factory.createNull()).when(ts2.isExpression, (v) => v).exhaustive();
}
function tsKeyword(keyword) {
  return match2(keyword).with("var", () => ts2.NodeFlags.None).with("let", () => ts2.NodeFlags.Let).with("const", () => ts2.NodeFlags.Const).exhaustive();
}
function tsVariableDeclaration(keyword, identifier, { eq: value, export_ }) {
  return ts2.factory.createVariableStatement(
    export_ ? [ts2.factory.createToken(ts2.SyntaxKind.ExportKeyword)] : void 0,
    ts2.factory.createVariableDeclarationList(
      [
        ts2.factory.createVariableDeclaration(
          ts2.factory.createIdentifier(identifier),
          void 0,
          void 0,
          tsLiteralOrExpression(value)
        )
      ],
      tsKeyword(keyword)
    )
  );
}
function tsFunctionCall({
  args,
  identifier,
  typeGenerics
}) {
  return ts2.factory.createCallExpression(
    ts2.factory.createIdentifier(identifier),
    typeGenerics?.map(
      (t) => ts2.factory.createTypeReferenceNode(ts2.factory.createIdentifier(t), void 0)
    ),
    args?.map(tsLiteralOrExpression)
  );
}
function tsObject(...properties) {
  return ts2.factory.createObjectLiteralExpression(
    properties.map(
      ([key, value]) => match2([key, value]).with(
        [P2.string, P2.nonNullable],
        ([key2, value2]) => ts2.factory.createPropertyAssignment(
          ts2.factory.createStringLiteral(key2),
          tsLiteralOrExpression(value2)
        )
      ).with(
        [P2.number, P2.nonNullable],
        ([key2, value2]) => ts2.factory.createPropertyAssignment(
          ts2.factory.createNumericLiteral(key2),
          tsLiteralOrExpression(value2)
        )
      ).with(
        [P2.string, P2.nullish],
        ([key2]) => ts2.factory.createShorthandPropertyAssignment(ts2.factory.createIdentifier(key2), void 0)
      ).with(
        [P2.when(ts2.isIdentifier), P2.nullish],
        ([key2]) => ts2.factory.createShorthandPropertyAssignment(key2, void 0)
      ).otherwise(([key2, value2]) => {
        throw unexpectedError({
          detail: `Unexpected key of value type creating ast ts object. key: ${key2}, value: ${value2}`
        });
      })
    )
  );
}
function tsArray(...elements) {
  return ts2.factory.createArrayLiteralExpression(elements.map(tsLiteralOrExpression));
}
function tsChainedMethodCall(identifier, ...chain) {
  const first = typeof identifier === "string" ? ts2.factory.createIdentifier(identifier) : identifier;
  if (chain.length === 0) return first;
  return chain.reduce(
    (expression, { args, identifier: identifier2, typeGenerics }) => ts2.factory.createCallExpression(
      ts2.factory.createPropertyAccessExpression(
        expression,
        ts2.factory.createIdentifier(identifier2)
      ),
      typeGenerics?.map(
        (t) => ts2.factory.createTypeReferenceNode(ts2.factory.createIdentifier(t), void 0)
      ),
      args?.map(tsLiteralOrExpression)
    ),
    first
  );
}
function tsNewLine() {
  return ts2.factory.createIdentifier("\n");
}
function tsRegex(pattern) {
  return ts2.factory.createRegularExpressionLiteral(pattern);
}

// src/converters/schemaObjectToAstZodSchema.ts
import { isReferenceObject as isReferenceObject4 } from "openapi3-ts";
import { P as P4, match as match4 } from "ts-pattern";

// src/converters/schemaObjectToZodValidators.ts
import { isReferenceObject as isReferenceObject3 } from "openapi3-ts";
import { P as P3, match as match3 } from "ts-pattern";
function schemaObjectToZodValidators(schema, options) {
  if (isReferenceObject3(schema)) {
    return buildOptionalNullableValidators(schema, options);
  }
  const zodValidators = match3(schema.type).with("string", () => buildZodStringValidators(schema)).with("number", "integer", () => buildZodNumberValidators(schema)).with("array", () => buildZodArrayValidators(schema)).with("object", () => buildZodObjectValidators(schema, options)).otherwise(() => []);
  zodValidators.push(...buildOptionalNullableValidators(schema, options));
  zodValidators.push(...buildDefaultValidator(schema));
  return zodValidators;
}
function buildOptionalNullableValidators(schema, options) {
  const zodValidators = [];
  if ("nullable" in schema && schema.nullable && !options?.isRequired)
    zodValidators.push({ identifier: "nullish" });
  else if ("nullable" in schema && schema.nullable) zodValidators.push({ identifier: "nullable" });
  else if (typeof options?.isRequired !== "undefined" && !options.isRequired)
    zodValidators.push({ identifier: "optional" });
  return zodValidators;
}
function buildDefaultValidator(schema) {
  const zodValidators = [];
  if (schema.default !== void 0) {
    const value = match3(schema.type).with("string", () => String(schema.default)).with("number", "integer", () => Number(schema.default)).with("boolean", () => Boolean(schema.default)).with("array", () => tsArray(...schema.default)).with(P3._, noop).exhaustive();
    if (value !== void 0) zodValidators.push({ args: [value], identifier: "default" });
  }
  return zodValidators;
}
function buildZodStringValidators(schema) {
  const zodValidators = [];
  if (!schema.enum) {
    if (schema.minLength) {
      zodValidators.push({ args: [schema.minLength], identifier: "min" });
    }
    if (schema.maxLength) {
      zodValidators.push({ args: [schema.maxLength], identifier: "max" });
    }
  }
  if (schema.pattern) {
    zodValidators.push({
      args: [tsRegex(sanitizeAndFormatRegex(schema.pattern))],
      identifier: "regex"
    });
  }
  const format2 = match3(schema.format).with("uuid", () => ({ identifier: "uuid" })).with("hostname", "uri", () => ({ identifier: "url" })).with("email", () => ({ identifier: "email" })).with("date-time", () => ({ args: [tsObject(["offset", true])], identifier: "datetime" })).with("ipv4", () => ({ args: [tsObject(["version", "v4"])], identifier: "ip" })).with("ipv6", () => ({ args: [tsObject(["version", "v6"])], identifier: "ip" })).otherwise(noop);
  if (format2) {
    zodValidators.push(format2);
  }
  return zodValidators;
}
function buildZodNumberValidators(schema) {
  const zodValidators = [];
  if (schema.enum) return zodValidators;
  if (schema.type === "integer") {
    zodValidators.push({ identifier: "int" });
  }
  if (schema.minimum !== void 0) {
    zodValidators.push({
      args: [schema.minimum],
      identifier: schema.exclusiveMinimum ? "gt" : "gte"
    });
  } else if (typeof schema.exclusiveMinimum === "number") {
    zodValidators.push({ args: [schema.exclusiveMinimum], identifier: "gt" });
  }
  if (schema.maximum !== void 0) {
    zodValidators.push({
      args: [schema.maximum],
      identifier: schema.exclusiveMaximum ? "lt" : "lte"
    });
  } else if (typeof schema.exclusiveMaximum === "number") {
    zodValidators.push({ args: [schema.exclusiveMaximum], identifier: "lt" });
  }
  if (schema.multipleOf !== void 0) {
    zodValidators.push({ args: [schema.multipleOf], identifier: "multipleOf" });
  }
  return zodValidators;
}
function buildZodArrayValidators(schema) {
  const zodValidators = [];
  if (schema.minItems !== void 0) {
    zodValidators.push({ args: [schema.minItems], identifier: "min" });
  }
  if (schema.maxItems !== void 0) {
    zodValidators.push({ args: [schema.maxItems], identifier: "max" });
  }
  return zodValidators;
}
function buildZodObjectValidators(schema, options) {
  const zodValidators = [];
  match3(schema.additionalProperties).with(false, noop).when(() => options?.strict === true, noop).otherwise(() => zodValidators.push({ identifier: "passthrough" }));
  return zodValidators;
}
function sanitizeAndFormatRegex(pattern) {
  const result = (pattern.startsWith("/") && pattern.endsWith("/") ? pattern.slice(1, -1) : pattern).replace(/\t/g, "\\t").replace(/\n/g, "\\n").replace(/\r/g, "\\r").replace(/([\u0000-\u0008\u000B\u000C\u000E-\u001F\u007F-\u009F\uFFFE\uFFFF])/g, (_m, p1) => {
    const dec = p1.codePointAt();
    const hex = dec.toString(16);
    if (dec <= 255) return `\\x${`00${hex}`.slice(-2)}`;
    return `\\u${`0000${hex}`.slice(-4)}`;
  }).replace(/\//g, "\\/");
  return `/${result}/`;
}

// src/converters/schemaObjectToAstZodSchema.ts
function toZodSchema(identifier, zodMethod, ...chainedMethods) {
  return tsChainedMethodCall(identifier, ...zodMethod ? [zodMethod] : [], ...chainedMethods);
}
function schemaObjectToAstZodSchema(schemaOrRef, ctx, validatorOptions) {
  function toZodSchemaWithValidators(identifier, zodMethod, customValidatorOptions = validatorOptions) {
    return toZodSchema(
      identifier,
      zodMethod,
      ...schemaObjectToZodValidators(schemaOrRef, customValidatorOptions)
    );
  }
  if (isReferenceObject4(schemaOrRef)) {
    const exportedSchema = ctx.componentSchemasMap.get(schemaOrRef.$ref);
    if (exportedSchema) {
      return toZodSchemaWithValidators(exportedSchema.normalizedIdentifier);
    }
  }
  const schema = ctx.resolveObject(schemaOrRef);
  return match4(schema).with({ oneOf: P4.nonNullable }, (s) => toZodSchemaWithValidators(fromOneOfSchemaObject(s, ctx))).with({ allOf: P4.nonNullable }, (s) => toZodSchemaWithValidators(fromAllOfSchemaObject(s, ctx))).with({ anyOf: P4.nonNullable }, (s) => toZodSchemaWithValidators(fromAnyOfSchemaObject(s, ctx))).with({ enum: P4.nonNullable }, (s) => fromEnumSchemaObject(s, toZodSchemaWithValidators)).with(
    { type: P4.array(P4.any) },
    (s) => s.type.length === 1,
    (s) => schemaObjectToAstZodSchema({ ...schema, type: s.type[0] }, ctx)
  ).with(
    { type: P4.array(P4.any) },
    (s) => s.type.length > 1,
    (s) => toZodSchemaWithValidators("z", {
      args: [
        tsArray(...s.type.map((type) => schemaObjectToAstZodSchema({ ...schema, type }, ctx)))
      ],
      identifier: "union"
    })
  ).with(
    { type: "string" },
    () => schema.format === "binary",
    () => toZodSchemaWithValidators("z", { identifier: "custom", typeGenerics: ["File"] })
  ).with({ type: "string" }, () => toZodSchemaWithValidators("z", { identifier: "string" })).with(
    { type: "number" },
    { type: "integer" },
    () => toZodSchemaWithValidators("z", { identifier: "number" })
  ).with({ type: "boolean" }, () => toZodSchemaWithValidators("z", { identifier: "boolean" })).with({ type: "null" }, () => toZodSchemaWithValidators("z", { identifier: "null" })).with(
    { type: "array" },
    { items: P4.nonNullable },
    () => toZodSchemaWithValidators("z", {
      args: [
        !schema.items ? toZodSchema("z", { identifier: "any" }) : schemaObjectToAstZodSchema(schema.items, ctx)
      ],
      identifier: "array"
    })
  ).with(
    { type: "object" },
    { properties: P4.nonNullable },
    { additionalProperties: P4.nonNullable },
    () => {
      if (!schema.properties || Object.keys(schema.properties).length === 0) {
        if (schema.additionalProperties === true) {
          return toZodSchemaWithValidators(
            "z",
            { args: [toZodSchema("z", { identifier: "any" })], identifier: "record" },
            {
              strict: true
            }
          );
        }
        if (typeof schema.additionalProperties === "object") {
          return toZodSchemaWithValidators(
            "z",
            {
              args: [schemaObjectToAstZodSchema(schema.additionalProperties, ctx)],
              identifier: "record"
            },
            { strict: true }
          );
        }
      }
      return toZodSchemaWithValidators("z", {
        args: [tsObject(...buildSchemaObjectProperties(schema, ctx))],
        identifier: "object"
      });
    }
  ).with({ type: P4.nullish }, () => toZodSchemaWithValidators("z", { identifier: "unknown" })).otherwise((s) => {
    throw unexpectedError({
      detail: `Unsupported schema type:
${JSON.stringify(s, null, 2)}`
    });
  });
}
function fromEnumSchemaObject(schema, buildZodSchemaWithValidators) {
  const schemaEnum = schema.enum;
  function resolveEnumValue(value) {
    if (value === null) return "null";
    return value;
  }
  if (schema.type === "string") {
    if (schemaEnum.length === 1) {
      return buildZodSchemaWithValidators("z", {
        args: [resolveEnumValue(schemaEnum[0])],
        identifier: "literal"
      });
    }
    return buildZodSchemaWithValidators("z", {
      args: [tsArray(...schemaEnum.map(resolveEnumValue))],
      identifier: "enum"
    });
  }
  if (schemaEnum.some((e) => typeof e === "string")) {
    return buildZodSchemaWithValidators("z", { identifier: "never" });
  }
  if (schemaEnum.length === 1) {
    return buildZodSchemaWithValidators("z", { args: [schemaEnum[0]], identifier: "literal" });
  }
  return buildZodSchemaWithValidators("z", {
    args: [
      tsArray(
        ...schemaEnum.map(
          (value) => buildZodSchemaWithValidators("z", {
            args: [resolveEnumValue(value)],
            identifier: "literal"
          })
        )
      )
    ],
    identifier: "enum"
  });
}
function fromOneOfSchemaObject(schema, ctx, validatorOptions) {
  if (schema.oneOf.length === 1) {
    return schemaObjectToAstZodSchema(schema.oneOf[0], ctx, validatorOptions);
  }
  return toZodSchema("z", {
    args: [
      tsArray(
        ...schema.oneOf.map((schema2) => schemaObjectToAstZodSchema(schema2, ctx, validatorOptions))
      )
    ],
    identifier: "union"
  });
}
function fromAllOfSchemaObject(schema, ctx) {
  if (schema.allOf.length === 1) {
    return schemaObjectToAstZodSchema(schema.allOf[0], ctx);
  }
  const schemas = schema.allOf.map((s) => schemaObjectToAstZodSchema(s, ctx));
  return toZodSchema(
    schemas[0],
    // Schema1
    void 0,
    ...schemas.slice(1).map((s) => ({ args: [s], identifier: "and" }))
    // .and(Schema2).and(Schema3) ...
  );
}
function fromAnyOfSchemaObject(schema, ctx) {
  if (schema.anyOf.length === 1) {
    return schemaObjectToAstZodSchema(schema.anyOf[0], ctx);
  }
  const schemas = schema.anyOf.map((s) => schemaObjectToAstZodSchema(s, ctx));
  const schemasPowerSet = generatePowerset(schemas).slice(1).reverse();
  const subsets = schemasPowerSet.map((set) => {
    if (set.length === 1) return set[0];
    return toZodSchema(
      set[0],
      // Schema1
      void 0,
      ...set.slice(1).map((s) => ({ args: [s], identifier: "merge" }))
      // .merge(Schema2).merge(Schema3) ...
    );
  });
  return toZodSchema("z", { args: [tsArray(...subsets)], identifier: "union" });
}
function buildSchemaObjectProperties(schema, ctx) {
  if (!schema.properties) return [];
  return Object.entries(schema.properties).map(([key, refOrSchema]) => {
    const isRequired = Boolean(schema.required?.includes(key));
    return [key, schemaObjectToAstZodSchema(refOrSchema, ctx, { isRequired })];
  });
}

// src/converters/apiOperationToAstTsRestContract.ts
function apiOperationToAstTsRestContract(operation, ctx) {
  const contractProperties = [];
  contractProperties.push(["method", operation.method.toUpperCase()]);
  contractProperties.push(["path", toContractPath(operation.path)]);
  const summary = operation.summary ?? operation.description;
  if (summary) {
    contractProperties.push(["summary", summary]);
  }
  const paramTypes = [
    ["header", "headers"],
    ["query", "query"],
    ["path", "pathParams"]
  ];
  paramTypes.forEach(([openApiParamType, tsContractParamType]) => {
    const params = operation.parameters.filter((param) => param.in === openApiParamType);
    if (params.length > 0) {
      contractProperties.push([
        tsContractParamType,
        toContractParameters(params, tsContractParamType, operation, ctx)
      ]);
    }
  });
  if (operation.requestBody || operation.method !== "get") {
    contractProperties.push(...toContractBodyAndContentType(operation.requestBody, operation, ctx));
  }
  contractProperties.push([
    "responses",
    tsObject(...toContractResponses(operation.responses, operation, ctx))
  ]);
  const contractOperationName = operation.operationId ? camelcase2(operation.operationId) : convertPathToVariableName(operation.path);
  return [contractOperationName, tsObject(...contractProperties)];
}
function toContractResponses(responses, apiOperation, ctx) {
  const responsesResult = [];
  for (const [statusCode, response] of Object.entries(responses)) {
    let buildResponse2 = function(statusCode2, contentType, zodSchema) {
      const statusCodeNum = Number(statusCode2);
      if (contentType === APPLICATION_JSON) {
        return [statusCodeNum, zodSchema];
      }
      return [
        statusCodeNum,
        tsChainedMethodCall("c", {
          args: [tsObject(["contentType", contentType], ["body", zodSchema])],
          identifier: "otherResponse"
        })
      ];
    };
    var buildResponse = buildResponse2;
    const contentObject = response.content;
    match5(statusCode.toLowerCase()).when(
      (statusCode2) => /^[1-5]\d\d$/.test(statusCode2),
      () => {
        const { contentType, zodSchema } = getZodSchemaAndContentTypeFromContentObject(
          contentObject,
          ctx
        );
        responsesResult.push(buildResponse2(statusCode, contentType, zodSchema));
      }
    ).when(
      (statusCode2) => /^[1-5]xx$/.test(statusCode2),
      () => {
        if (statusCode === "2xx" && responsesResult.some(([r]) => r.toString().startsWith("2")))
          return;
        const statusCodes = POSSIBLE_STATUS_CODES_TS_REST_OUTPUT.filter(
          (c) => c.startsWith(statusCode[0]) && !Object.keys(responses).includes(c)
        );
        const { contentType, zodSchema } = getZodSchemaAndContentTypeFromContentObject(
          contentObject,
          ctx
        );
        statusCodes.forEach(
          (c) => responsesResult.push(buildResponse2(c, contentType, zodSchema))
        );
      }
    ).with("default", () => {
      const statusCodes = POSSIBLE_STATUS_CODES_TS_REST_OUTPUT.filter((c) => !responsesResult.find(([r]) => r.toString() === c)).filter(
        (c) => !(responsesResult.some(([r]) => r.toString().startsWith("2")) && c.startsWith("2"))
      );
      const { contentType, zodSchema } = getZodSchemaAndContentTypeFromContentObject(
        contentObject,
        ctx
      );
      statusCodes.forEach((c) => responsesResult.push(buildResponse2(c, contentType, zodSchema)));
    }).otherwise(() => {
      throw invalidStatusCodeError({
        method: apiOperation.method,
        path: apiOperation.path,
        statusCode
      });
    });
  }
  return responsesResult.filter(([, value]) => value !== void 0);
}
function toContractBodyAndContentType(body, apiOperation, ctx) {
  if (!body) return [["body", tsChainedMethodCall("c", { identifier: "noBody" })]];
  const { contentType, zodSchema } = getZodSchemaAndContentTypeFromContentObject(body.content, ctx);
  const bodyContentType = contentType.includes("json") ? APPLICATION_JSON : contentType;
  if (!TS_REST_RESPONSE_BODY_SUPPORTED_CONTENT_TYPES.find((r) => r === bodyContentType)) {
    throw unsupportedRequestBodyContentTypeError({
      contentType,
      method: apiOperation.method,
      path: apiOperation.path
    });
  }
  return [
    ["body", zodSchema],
    ["contentType", bodyContentType]
  ];
}
function toContractParameters(params, paramType, apiOperation, ctx) {
  const pathParams = params.map((param) => {
    if (!param.schema) {
      throw missingSchemaInParameterObjectError({
        method: apiOperation.method,
        paramType,
        path: apiOperation.path
      });
    }
    const isRequired = paramType === "pathParams" || param.required === true;
    const objectSchema = ctx.resolveObject(param.schema);
    return [param.name, schemaObjectToAstZodSchema(objectSchema, ctx, { isRequired })];
  });
  return tsChainedMethodCall("z", { args: [tsObject(...pathParams)], identifier: "object" });
}
function getZodSchemaAndContentTypeFromContentObject(content, ctx) {
  const defaultReturn = {
    contentType: APPLICATION_JSON,
    zodSchema: tsChainedMethodCall("c", { identifier: "noBody" })
  };
  if (!content) {
    return defaultReturn;
  }
  const contentType = Object.keys(content)[0];
  const maybeSchemaObject = content[contentType]?.schema;
  if (!contentType || !maybeSchemaObject) {
    return defaultReturn;
  }
  const zodSchema = schemaObjectToAstZodSchema(maybeSchemaObject, ctx, { isRequired: true });
  return { contentType, zodSchema };
}
function toContractPath(path) {
  return path.replace(/{/g, ":").replace(/}/g, "");
}

// src/lib/prettier.ts
import { format } from "prettier";
import * as parserTypescript from "prettier/parser-typescript";
import * as prettierPluginEstree from "prettier/plugins/estree";
async function prettify(input, config) {
  try {
    return format(input.trim(), {
      parser: "typescript",
      ...config,
      plugins: [parserTypescript, prettierPluginEstree]
    });
  } catch (error) {
    return input;
  }
}

// src/generateContract.ts
async function generateContract({
  openApi,
  prettierConfig
}) {
  const openApiSchema = await SwaggerParser.bundle(openApi);
  const ctx = createContext(openApiSchema);
  const ast = new AstTsWriter();
  ast.add(tsNamedImport({ from: "@ts-rest/core", import_: ["initContract"] })).add(tsNamedImport({ from: "zod", import_: ["z"] })).add(tsNewLine()).add(
    tsVariableDeclaration("const", "c", { eq: tsFunctionCall({ identifier: "initContract" }) })
  ).add(tsNewLine());
  if (ctx.topologicallySortedSchemas.length > 0) {
    for (const { normalizedIdentifier, schema } of ctx.topologicallySortedSchemas) {
      ast.add(
        tsVariableDeclaration("const", normalizedIdentifier, {
          eq: schemaObjectToAstZodSchema(schema, ctx)
        })
      );
    }
    ast.add(tsNewLine());
    ast.add(
      tsVariableDeclaration("const", "schemas", {
        eq: tsObject(
          ...ctx.topologicallySortedSchemas.map(
            ({ normalizedIdentifier }) => [normalizedIdentifier]
          )
        ),
        export_: true
      })
    ).add(tsNewLine());
  }
  const tsRestAstContracts = ctx.apiOperationObjects.map(
    (operationObject) => apiOperationToAstTsRestContract(operationObject, ctx)
  );
  ast.add(
    tsVariableDeclaration("const", "contract", {
      eq: tsChainedMethodCall("c", {
        args: [tsObject(...tsRestAstContracts)],
        identifier: "router"
      }),
      export_: true
    })
  );
  return await prettify(ast.toString(), prettierConfig);
}
export {
  generateContract
};
